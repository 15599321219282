@import "../../styles/variables";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: min-content;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 3rem 2rem 2rem 2rem;
  background-color: rgb(0, 0, 0);
  justify-content: space-between;
}

.content {
  width: 100%;
  overflow-y: auto;
  padding: 4rem 3rem 2rem 3rem;
  position: relative;
}
