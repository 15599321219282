@import "../../../../styles/variables";

.title {
  font-size: 1rem;
  font-weight: $semi-bold-text;
  margin-bottom: 0;
}
.textContainer {
  display: flex;
  font-size: $text-font-size;
}

.key {
  width: 40%;
  font-weight: $bold-text;
}
