:where(.css-dev-only-do-not-override-mxhywb).ant-collapse-large>.ant-collapse-item>.ant-collapse-header {
  padding: 1rem 1.5rem;
  display: flex !important;
  align-items: center !important;
}

.css-dev-only-do-not-override-mxhywb.ant-collapse-large>.ant-collapse-item:first-child>.ant-collapse-header {
  padding-top: 0 !important;
}

:where(.css-mxhywb).ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center !important;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}