.container {
  width: 90%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding-left: 6rem;

}

.card {
  border: 1px solid #cfcfcf !important;
  text-align: center;
}

.innerContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  height: 100%;
  width: 100%;
}

.imgContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  height: 4rem;
  width: 100%;
}

.img {
  width: 8rem;
}
