:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
  font-size: 1.3em;
  font-family: "Inter", sans-serif !important;
}

.ant-menu, .ant-menu-root, .ant-menu-inline, .ant-menu-light, .css-dev-only-do-not-override-mxhywb {
  font-family: "", sans-serif !important;
  padding-left: 10px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-light .ant-menu-item-selected {
  color: #484848 !important;
  font-weight: 500 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
  color: #484848 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline .ant-menu-item {
  height: 2rem !important;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 2rem !important;
}

.folder-icon {
  color: rgba(5, 186, 174, 0.372) !important;
}

.get {
  color: #000000 !important;
}

.post {
  color: #fbb92b !important;

}

.put {
  color: #479bf4 !important;

}

.get, .post, .put {
  font-size: 0.5rem !important;
  font-weight: 500 !important;
}

.collapseButton, .uncollapseButton {
  width: 100%;
  display: flex;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.2rem 0;
  color: #6b6e89;
}

.collapseButton {
  justify-content: flex-start;
  padding-left: 0.5rem;
}

.uncollapseButton {
  justify-content: center;
  border-bottom: solid 0.1rem rgb(212, 212, 212);
}

/* Collapsed menu */

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline-collapsed.ant-menu-root .ant-menu-item>.ant-menu-inline-collapsed-noicon {
  font-size: 0.75rem !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-vertical .ant-menu-item {
  height: 2rem !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline-collapsed {
  width: 100% !important;
  color: black !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  font-size: 1rem !important;
  padding-left: 0.5rem !important;
}


:where(.css-mxhywb).ant-menu-light .ant-menu-item-selected {
  background-color: #e5e5e5 !important;
  color: #484848 !important;
  font-weight: 500 !important;
}

:where(.css-mxhywb).ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content, :where(.css-mxhywb).ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

:where(.css-mxhywb).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-mxhywb).ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none !important;
}


/* New Menu */

.sideBar {
  background-color: #000000 !important;
  padding: 0;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-dark {
  color: rgba(255, 255, 255, 0.65);
  background: #000000 !important;
  font-weight: 500;
  font-size: 5rem !important;
}

:where(.css-mxhywb).ant-menu-dark .ant-menu-item-selected {
  border-bottom: solid 0.1rem white !important;
  border-radius: 0 !important;
}

:where(.css-mxhywb).ant-menu-dark .ant-menu-item-selected:where(.css-dev-only-do-not-override-mxhywb).ant-menu-dark .ant-menu-item-selected {
  border-bottom: solid 0.1rem white !important;
  border-radius: 0;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline .ant-menu-item, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-vertical .ant-menu-item, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline .ant-menu-submenu-title, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-vertical .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  padding-inline: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 0;
  margin-block: 0;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline .ant-menu-item {
  height: 2.5rem !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom: solid 0.1rem white !important;
  border-radius: 0 !important;
}

:where(.css-mxhywb).ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0) !important;
}