@import "../../styles/variables";

.container {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.indicator {
  background-color: $color-black;
  font-size: 0.7rem;
  font-weight: $bold-text;
  color: white;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  margin-right: 1.5rem;
}
