@import "../../../styles/variables";

$plain-text-color: #6b6b6b;
$function-color: #52ab31;
$ipText-color: #d1474a;

.container {
  border: solid 0.1rem rgb(227, 227, 227);
  border-radius: 0.3rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.innerContainer {
  max-height: 13rem;
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.plainText {
  color: $plain-text-color;
  padding: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 0.3rem;
  font-size: $text-font-size;
  font-weight: $bold-text;
  margin: 0;
}

.copyIcon {
  cursor: pointer;
}

.bodyText {
  font-family: $roboto-mono;
}
.smallText {
  font-size: $text-font-size;
  color: $text-color;
  margin-bottom: 1.5rem;
}

.function {
  color: $function-color;
}

.ipText {
  color: $ipText-color;
}

// exmpleCodeContainer

.codeWithBarContainer {
  width: 45%;
}

.exmpleCodeContainer {
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: #303030;
  color: white;
}

.exmpleCodeInnerContainer {
  margin-bottom: 1rem;
}

.codeInnerContainer {
  margin-top: 1rem;
}

.exampleCodeText {
  font-size: $text-font-size;
  font-family: $roboto-mono;
  overflow-y: auto;
  height: min-content;
  max-height: 13rem;
}

.requestTitle {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  font-size: 0.8rem;
  margin: 0.5rem 0 1rem 0;

  p {
    margin: 0;
  }
}

.exmpleCodeBody {
  border: solid 0.1rem rgba(236, 236, 236, 0.128);
  border-radius: 0.2rem;
  padding: 0.5rem;
  overflow-wrap: break-word;
  background-color: #212121;
  position: relative;
}

.functionYellow {
  color: #ffee57;
}

.functionGreen {
  color: rgb(121, 201, 0);
}

.greenText {
  color: $text-color-green;
}
.blueText {
  color: $text-color-blue;
}

.requestOptionsValues {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
}

.bodyAndHeadersSelection {
  display: flex;
  font-size: $text-font-size;
  margin-top: 0.5rem;
}

.bodyOrHeadersItem {
  margin-right: 1rem;
  cursor: pointer;
  padding-bottom: 0.3rem;
}

.bodyOrHeadersItemSelected {
  border-bottom: solid orange 0.15rem;
  border-radius: 0.15rem;
}

.headersContainer {
  display: flex;
  font-family: $rubik;
}

.headersContainerKey {
  font-size: $text-font-size;
  font-weight: $bold-text;
  margin-right: 4rem;
}

.jsonCodeContainer {
  height: 15rem;
}

.noHeaders {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $rubik;
}

// Dropdown

.dropdownContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  width: max-content;
  max-width: 13rem;
}

.value {
  margin-bottom: 0;
  padding-right: 1rem;
}

.icon {
  font-size: 0.9rem;
  display: flex;
}
