@import "../../../styles/variables";

.textInnerContentContainer {
  width: 60%;
  margin: auto;
  color: $text-color;
  height: min-content;
  position: relative;
}

.section {
  width: 80%;
  margin-top: 3rem;
}

.title {
  font-size: 2.5rem;
}

.orderedList {
  margin-left: 0;
  padding-left: 1rem;
  li {
    font-size: 1rem;
  }
}

.image {
  width: 100%;
  margin-left: -5rem;
}
