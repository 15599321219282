@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: c;
}

.user {
  font-size: $small-font-size;
  font-weight: $semi-bold-text;
  margin-right: 0.5rem;
  color: white;
}
