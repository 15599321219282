@import "../../../styles/variables";

.container {
  font-size: $text-font-size;
  font-family: $roboto-mono;
  border: solid 0.1rem rgb(210, 210, 210);
  border-radius: 0.2rem;
  background-color: #f2f2f2;
  padding: 0.3rem;
  overflow-wrap: break-word;
  margin-bottom: 1.5rem;
}
