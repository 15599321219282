@import "../../../styles/variables";

.container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}

.textInnerContentContainer {
  width: 80%;
  margin: auto;
  color: $text-color;
  height: min-content;
  padding-left: 3rem;
}

.innerContentContainer {
  width: 80%;
  margin: auto;
  color: $text-color;
  padding: 0.5rem 0.5rem 0 3rem;
  height: min-content;
}

.welcome {
  width: 80%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: -2rem;
  color: $text-color;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 0;
  }

  p {
    font-size: 1.3rem;
  }
}

.sectionTitle {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 2rem 0 0.5rem 0;
}

.grayedOutText {
  color: rgb(187, 187, 187);
}

.title {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.subTitle {
  font-size: $subtitle-font-size;
  font-weight: $subtitle-font-weight;
  margin-bottom: 1rem;
  margin: 0 0 1rem 0;
}
.subTitleH3 {
  font-size: $subtitleh3-font-size;
  font-weight: $subtitle-font-weight;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.paragraph {
  margin-bottom: 1.25rem;
}

.mediumText {
  font-size: $medium-font-size;
  color: $text-color;
  line-height: 1.3rem;
}

.boldText {
  font-weight: $bold-text;
}

.span {
  line-height: 1.3rem;
}

.image {
  width: 80%;
  margin: auto;
}

.li {
  padding-left: 1rem;
  line-height: 2rem;
}

.greenText {
  color: $text-color-green;
}

.orangeText {
  color: $text-color-orange;
}

.blueText {
  color: $text-color-blue;
}

.titleSpace {
  margin-right: 1rem;
}

.link {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

.bodyContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.payloadButton {
  font-size: 0.7rem;
  padding: 0.1rem 0.3rem !important;
  margin: 0 0rem 0.3rem 0.3rem;
  color: rgb(90, 90, 90);
}

.selectedPayload {
  border: solid 0.1rem rgb(99, 99, 99) !important;
  color: $text-color;
  font-weight: $semi-bold-text;
}

// Text and code
.textAndCodeContainer,
.transactionTextAndCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
}

.transactionTextAndCode {
  align-items: flex-start;
}

.textContainer {
  width: 45%;
  padding-right: 2rem;
  font-size: $medium-font-size;
  margin-top: 0.5rem;
  line-height: 1.2rem;
}

.transactionTextContainer,
.codeContainer {
  width: 80%;
}

.textContainer > p {
  margin: 0;
}

.text {
  font-size: $medium-font-size;
}

.content_ul {
  margin: 0;
  list-style-type: none;
}
.content_ul > li {
  margin-bottom: 0.5rem;
}
.content_ul > li:before {
  content: "-";
  padding-right: 1rem;
}
.content_ul > li > span {
  left: 20px;
  font-size: 1rem;
  line-height: 2rem;
}

// panelHeader //

.panelHeader {
  margin: 0;
  font-size: 1.7rem;
  color: $text-color;
}
