@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Poppins:wght@300;400;500&family=Roboto+Mono:wght@300;400&family=Rubik:wght@300;400;500&family=Ubuntu:wght@300;400;500&display=swap&family=Inter:wght@100..900&display=swap");

//Fonts
$poppins: "Poppins", sans-serif;
$montserrat: "Montserrat", sans-serif;
$roboto-mono: "Roboto Mono", monospace;
$ubuntu: "Ubuntu", sans-serif;
$rubik: "Rubik", sans-serif;
$inter: "Inter", sans-serif;

//Colors
$color-black: #37352f;
$color-white: #fff;

$text-color-green: #0cbb52;
$text-color-orange: #fbb92b;
$text-color-blue: #479bf4;
$text-color-red: #f35944;
$link-color: #819bd3;
$gray-color: #a9a9a9;

//Content
$text-content-width: 70%;

//Text
$extraSmall-font-size: 0.75rem;
$small-font-size: 0.8rem;
$text-font-size: 1rem;
$medium-font-size: 1em;
$navbar-text-font-size: 1.2rem;
$text-color: #37352f;
$bold-text: 600;
$semi-bold-text: 500;

//Title
$title-font-size: 1.5rem;
$title-font-weight: $bold-text;

//Subtitle
$subtitle-font-size: 1.8rem;
$subtitle-font-weight: $bold-text;
$subtitleh3-font-size: 1.3rem;
