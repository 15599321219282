@import "../../styles/variables";

.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  width: 20%;
  margin-top: -25rem;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.logo {
  width: 10rem;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
  font-size: $medium-font-size;
  font-weight: $semi-bold-text;
}

.signupModal {
  background-color: white !important;
  padding-bottom: 0 !important;
}

.form {
  height: 12rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  height: 8rem;
}

.signinInputContainer {
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.radioGroup {
  display: flex;
  align-items: center;
}

.radioButton {
  font-size: $medium-font-size;
  background-color: white !important;
  margin-right: 1rem;
}

.submit {
  width: 100% !important;
  font-weight: $semi-bold-text;
}

.detailsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.input {
  margin-bottom: 1rem;
}

.logout {
  color: white;
  background-color: black !important;
  font-size: $extraSmall-font-size;
  font-weight: $bold-text;
  outline: none !important;
  box-shadow: none !important;
}

.formErrorMessage,
.errorMessage {
  font-size: $text-font-size;
  font-weight: $bold-text;
  color: $text-color-red;
  text-align: center;
  margin-top: 0.2rem;
}

.errorMessage {
  text-align: left;
  margin-bottom: 0.2rem;
}

.createAccount {
  font-size: $text-font-size;
  text-align: center;
  font-weight: $semi-bold-text;
  cursor: pointer;
}

.descopeContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.descopeInnerContainer {
  width: 30%;
  margin-top: 5rem;
}
